<template>
  <div v-if="loaded" class="nevs-content">
    <div class="nevs-content-top-buttons">
      <NevsButton class="success" @click="saveClick()"><i class="fa-solid fa-floppy-disk"></i>
        {{ $LANG.Get('buttons.save') }}
      </NevsButton>&nbsp;
      <NevsButton class="error" @click="backClick"><i class="fa-solid fa-arrow-left"></i> {{
          $LANG.Get('buttons.back')
        }}
      </NevsButton>
    </div>
    <NevsCard class="form">
      <h2>{{ $LANG.Get('labels.generalData') }}</h2>
      <NevsTextField v-model="company.name" :error="companyValidation.name"
                     :label="'*' + $LANG.Get('fields.name') + ':'"></NevsTextField>
      <NevsTextField v-model="company.oib" :error="companyValidation.oib"
                     :label="$LANG.Get('fields.oib') + ':'"></NevsTextField>
      <NevsCheckbox v-model="company.foreign" :label="$LANG.Get('fields.foreignClient')"></NevsCheckbox>
      <NevsTextField v-if="company.foreign" v-model="company.oib_label" :error="companyValidation.oib_label"
                     :label="$LANG.Get('fields.oibLabel') + ':'"></NevsTextField>
      <NevsCheckbox v-model="company.active" :label="$LANG.Get('fields.activeF')"></NevsCheckbox>
    </NevsCard>
    <NevsCard class="form form-float">
      <h2>{{ $LANG.Get('labels.emails') }}</h2>
      <div v-if="companyValidation.emails" class="box-error nevs-text-error">{{ companyValidation.emails }}</div>
      <div v-for="(email, key) in company.emails" :key="key" class="box-form">
        <NevsTextField :error="email.validation" :label="'*' + $LANG.Get('fields.address') + ':'" v-model="email.address"></NevsTextField>
        <NevsCheckbox :label="$LANG.Get('fields.emailOffers')" v-model="email.offers"></NevsCheckbox>
        <NevsCheckbox :label="$LANG.Get('fields.emailInvoices')" v-model="email.invoices"></NevsCheckbox>
        <span @click="removeRecord(key, 'emails')" :title="$LANG.Get('tooltips.removeItem')" class="box-form-remove-button"><i class="fa-solid fa-trash"></i></span>
      </div>
      <NevsButton @click="addRecord('emails')">{{ $LANG.Get('buttons.add') }}</NevsButton>
    </NevsCard>
    <NevsCard class="form form-float">
      <h2>{{ $LANG.Get('labels.phones') }}</h2>
      <div v-if="companyValidation.phones" class="box-error nevs-text-error">{{ companyValidation.phones }}</div>
      <div v-for="(phone, key) in company.phones" :key="key" class="box-form">
        <NevsTextField :error="phone.validation.name" :label="'*' + $LANG.Get('fields.name') + ':'" v-model="phone.name"></NevsTextField>
        <NevsTextField :error="phone.validation.number" :label="'*' + $LANG.Get('fields.number') + ':'" v-model="phone.number"></NevsTextField><br /><br /><br />
        <span @click="removeRecord(key, 'phones')" :title="$LANG.Get('tooltips.removeItem')" class="box-form-remove-button"><i class="fa-solid fa-trash"></i></span>
      </div>
      <NevsButton @click="addRecord('phones')">{{ $LANG.Get('buttons.add') }}</NevsButton>
    </NevsCard>
    <NevsCard class="form form-float">
      <h2>{{ $LANG.Get('labels.addresses') }}</h2>
      <div v-if="companyValidation.addresses" class="box-error nevs-text-error">{{ companyValidation.addresses }}</div>
      <div v-for="(address, key) in company.addresses" :key="key" class="box-form">
        <NevsTextArea :error="address.validation.address" :label="$LANG.Get('fields.streetAndHouseNo')" v-model="address.address"></NevsTextArea>
        <NevsTextField :error="address.validation.postal_code" :label="$LANG.Get('fields.postalCode')" v-model="address.postal_code"></NevsTextField>
        <NevsTextField  :error="address.validation.city" :label="$LANG.Get('fields.city')" v-model="address.city"></NevsTextField>
        <NevsTextField  :error="address.validation.country" :label="$LANG.Get('fields.country')" v-model="address.country"></NevsTextField>
        <NevsCheckbox :label="$LANG.Get('fields.officialF')" v-model="address.official"></NevsCheckbox>
        <span @click="removeRecord(key, 'addresses')" :title="$LANG.Get('tooltips.removeItem')" class="box-form-remove-button"><i class="fa-solid fa-trash"></i></span>
      </div>
      <NevsButton @click="addRecord('addresses')">{{ $LANG.Get('buttons.add') }}</NevsButton>
    </NevsCard>
  </div>
</template>

<script>
import Company from '@/models/Company';
import NevsCard from "@/components/nevs/NevsCard";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsCheckbox from "@/components/nevs/NevsCheckbox";
import NevsButton from "@/components/nevs/NevsButton";
import NevsTextArea from "@/components/nevs/NevsTextArea";

export default {
  name: "EntityCompany",
  components: {NevsTextArea, NevsButton, NevsCheckbox, NevsTextField, NevsCard},
  data() {
    return {
      loaded: false,
      mode: this.$route.params.id === '0' ? 'add' : 'edit',
      company: new Company({id: this.$route.params.id}),
      companyValidation: new Company({}, true),
      templates:{
        emails: {
          address: '',
          invoices: false,
          offers: false,
          validation: ''
        },
        phones: {
          number: '',
          name: '',
          validation: {
            number: '',
            name: ''
          }
        },
        addresses: {
          address: '',
          postal_code: '',
          city: '',
          country: '',
          official: false,
          validation: {
            address: '',
            postal_code: '',
            city: '',
            country: ''
          }
        }
      }
    }
  },
  watch: {
    'company.foreign'() {
      if (this.loaded) {
       this.company.oib_label = 'OIB';
      }
    }
  },
  methods: {
    removeRecord(key, type) {
      let vm = this;
      this.$LOCAL_BUS.TriggerEvent('popup', {
        type: 'confirm', text: this.$LANG.Get('alerts.generalRemovalQuestion'), callback: (response) => {
          if (response) {
            vm.company[type].splice(key, 1);
          }
        }
      });
    },
    addRecord(type) {
      this.company[type].push(JSON.parse(JSON.stringify(this.templates[type])))
    },
    backClick() {
      if (window.history.length === 1) {
        window.close();
      } else {
        this.$router.back();
      }
    },
    saveClick() {
      if (this.company.addresses.length === 0) {
        this.company.addresses.push(this.templates.addresses);
      }
      if (this.company.addresses.length === 1) {
        this.company.addresses[0].official = true;
      }

      let valid = true;

      if (this.company.name === '') {
        valid = false;
        this.companyValidation.name = this.$LANG.Get('labels.mandatoryField');
      } else {
        this.companyValidation.name = '';
      }

      if (this.company.last_name === '') {
        valid = false;
        this.companyValidation.last_name = this.$LANG.Get('labels.mandatoryField');
      } else {
        this.companyValidation.last_name = '';
      }

      if (this.company.oib !== '') {
        if (!this.company.foreign) {
          if (!this.$HELPERS.CheckOIB(this.company.oib)) {
            valid = false;
            this.companyValidation.oib = this.$LANG.Get('labels.invalidOIB');
          } else {
            this.companyValidation.oib = '';
          }
        } else {
          this.companyValidation.oib = '';
        }
      }

      if (this.company.emails.length !== 0) {
        let invoices = false;
        let offers = false;
        for (let email of this.company.emails) {
          if (email.invoices) {
            invoices = true;
          }
          if (email.offers) {
            offers = true;
          }
          if (email.address === '') {
            valid = false;
            email.validation = this.$LANG.Get('labels.mandatoryField');
          } else {
            if (!this.$HELPERS.CheckEmail(email.address)) {
              valid = false;
              email.validation = this.$LANG.Get('labels.invalidEmailAddress');
            } else {
              email.validation = '';
            }
          }
        }
        if (!(invoices && offers)) {
          valid = false;
          this.companyValidation.emails = this.$LANG.Get('labels.defaultEmailFail');
        } else {
          this.companyValidation.emails = '';
        }
      } else {
        this.companyValidation.emails = '';
      }

      let official = 0;
      for (let address of this.company.addresses) {
        if (address.official) {
          official++;
        }
      }
      if (official !== 1) {
        valid = false;
        this.companyValidation.addresses = this.$LANG.Get('labels.defaultAddressFail');
      } else {
        this.companyValidation.addresses = '';
      }

      for (let phone of this.company.phones) {
        if (phone.name === '') {
          valid = false;
          phone.validation.name = this.$LANG.Get('labels.mandatoryField');
        } else {
          phone.validation.name = '';
        }
        if (phone.number === '') {
          valid = false;
          phone.validation.number = this.$LANG.Get('labels.mandatoryField');
        } else {
          phone.validation.number = '';
        }
      }

      if (valid) {
        let vm = this;
        let requestMethod = this.mode === 'add' ? 'post' : 'put';
        let requestService = this.mode === 'add' ? 'companies' : 'companies/' + this.company.id;
        this.$API.APICall(requestMethod, requestService, this.company, (data, success) => {
          if (success) {
            vm.$CROSS_TAB_BUS.TriggerEvent('reload-companies', {});
            vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
            vm.backClick();
          } else {
            if (data.error === 'oib_duplicate') {
              vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.oibDuplicate'), type: 'alert'});
            } else {
              vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
            }
          }
        })
      }
    }
  },
  mounted() {
    this.$store.commit('selectMenu', 'clients');
    this.$store.commit('selectSubMenu', 'companies');

    if (this.mode === 'add') {
      this.$store.commit('setBreadcrumbs', [
        {
          label: this.$LANG.Get('modules.companies'),
          link: '/companies'
        },
        {
          label: this.$LANG.Get('modules.newCompany'),
          link: null
        }
      ]);
      this.loaded = true;
    } else {
      let vm = this;
      this.$API.APICall('get', 'companies/' + this.company.id, {}, (data, success) => {
        if (success) {
          if (data.company !== null) {
            vm.company.Fill(data.company);
            vm.$store.commit('setBreadcrumbs', [
              {
                label: vm.$LANG.Get('modules.companies'),
                link: '/companies'
              },
              {
                label: vm.company.name,
                link: null
              }
            ]);
          } else {
            vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
          }
        } else {
          vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
        }
        vm.$nextTick(() => {
          vm.loaded = true;
        });
      });
    }
  }
}
</script>

<style scoped>
.form {
  max-width: 300px;
  margin-bottom: 10px;
  padding: 15px;
}

.form-float {
  width: 300px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 15px;
}

.form-float h2 {
  margin: 0 0 5px;
}

.box-form {
  background: #c6d8e2;
  border-radius: 5px;
  margin-bottom: 5px;
  position: relative;
}

.box-error {
  font-size: 12px;
  margin-bottom: 5px;
  text-align: justify;
}

.box-form-remove-button {
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 5px;
}
</style>