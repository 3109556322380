import ModuleDashboard from "@/components/modules/ModuleDashboard";
import ModuleUsers from "@/components/modules/ModuleUsers";
import EntityUser from "@/components/entities/EntityUser";
import GeneralData from "@/components/general/GeneralData";
import FinancialSettings from "@/components/general/FinancialSettings";
import EmailSettings from "@/components/general/EmailSettings";
import ModulePersons from "@/components/modules/ModulePersons";
import EntityPerson from "@/components/entities/EntityPerson";
import ModuleCompanies from "@/components/modules/ModuleCompanies";
import EntityCompany from "@/components/entities/EntityCompany";
import ModuleProducts from "@/components/modules/ModuleProducts";
import ModuleOffers from "@/components/modules/ModuleOffers";
import EntityOffer from "@/components/entities/EntityOffer";
import ModuleInvoices from "@/components/modules/ModuleInvoices";
import EntityInvoice from "@/components/entities/EntityInvoice";
import ModuleDailyReport from "@/components/modules/ModuleDailyReport";
import ModuleKPR from "@/components/modules/ModuleKPR";
import ModulePOSD from "@/components/modules/ModulePOSD";
import ModuleWordOrders from "@/components/modules/ModuleWorkOrders";
import EntityWorkOrder from "@/components/entities/EntityWorkOrder";

export default [
    { path: '/', component: ModuleDashboard },
    { path: '/users', component: ModuleUsers,  meta: { transition: 'slide-left' } },
    { path: '/users/:id', component: EntityUser },
    { path: '/general-data', component: GeneralData },
    { path: '/financial-settings', component: FinancialSettings },
    { path: '/email-settings', component: EmailSettings },
    { path: '/persons', component: ModulePersons },
    { path: '/persons/:id', component: EntityPerson },
    { path: '/companies', component: ModuleCompanies },
    { path: '/companies/:id', component: EntityCompany },
    { path: '/products', component: ModuleProducts },
    { path: '/offers', component: ModuleOffers },
    { path: '/offers/:id/:mode', component: EntityOffer },
    { path: '/invoices', component: ModuleInvoices },
    { path: '/invoices/:id/:mode', component: EntityInvoice },
    { path: '/daily-report', component: ModuleDailyReport},
    { path: '/kpr', component: ModuleKPR},
    { path: '/posd', component: ModulePOSD},
    { path: '/work-orders', component: ModuleWordOrders },
    { path: '/work-orders/:id/:mode', component: EntityWorkOrder },
]