import moment from "moment/moment";
import Settings from "@/config.json";

class Offer {
    id = null;
    number = '';
    time_of_issue = null;
    valid_until = null;
    currency = '';
    currency_rate = 1;
    discount = 0;
    amount = 0;
    invoices = [];
    history = [];
    tax_system = false;
    footer = '';
    user_id = null;
    customer_id = null;
    customer_data = {};
    offer_status_id = 1;
    sequence = 0;
    device = '';
    location = '';
    offer_sequence_id = null;
    zki = '';
    jir = '';
    hash = '';
    items = [];
    ppo = false;
    locale = '';
    iban = '';
    swift = '';

    constructor(data, validation = false) {
        this.currency = 'EUR';
        if (moment().format('YYYY-MM-DD HH:mm:ss') < Settings.EUR_TRANSITION) {
            this.currency = 'HRK';
        }

        for (const [key, value] of Object.entries(data)) {
            this[key] = value;
        }

        if (validation) {
            for (const [key] of Object.entries(this)) {
                this[key] = '';
            }
        }
    }

    Fill(data) {
        for (const [key] of Object.entries(this)) {
            if (data[key] !== undefined) {
                this[key] = data[key];
            }
        }
    }
}

export default Offer;
