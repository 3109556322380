class WorkOrder {
    id = null;
    number = '';
    time_of_issue = null;
    currency = '';
    currency_rate = 1;
    discount = 0;
    amount = 0;
    invoices = [];
    history = [];
    tax_system = false;
    footer = '';
    note = '';
    user_id = null;
    customer_id = null;
    customer_data = {};
    work_order_status_id = 1;
    sequence = 0;
    device = '';
    location = '';
    work_order_sequence_id = null;
    zki = '';
    jir = '';
    hash = '';
    items = [];
    ppo = false;
    locale = '';
    iban = '';
    swift = '';

    constructor(data, validation = false) {
        this.currency = 'EUR';

        for (const [key, value] of Object.entries(data)) {
            this[key] = value;
        }

        if (validation) {
            for (const [key] of Object.entries(this)) {
                this[key] = '';
            }
        }
    }

    Fill(data) {
        for (const [key] of Object.entries(this)) {
            if (data[key] !== undefined) {
                this[key] = data[key];
            }
        }
    }
}

export default WorkOrder;
