<template>
    <div v-if="loaded === 4" class="nevs-content">
        <div class="nevs-content-top-buttons">
            <NevsButton class="success" @click="saveClick()"><i class="fa-solid fa-floppy-disk"></i>
                {{ $LANG.Get('buttons.save') }}
            </NevsButton>&nbsp;
        </div>

        <NevsCard class="form">
            <h2>{{ $LANG.Get('labels.defaultSettings') }}</h2>
            <NevsSelect v-model="client.default_measurement_unit_id" :ajax="'select/measurement-units'"
                        :label="$LANG.Get('fields.defaultMeasurementUnitId') + ':'"
                        :nullable="true"
                        class="half"></NevsSelect>
            <NevsSelect v-model="client.default_payment_method_id" :ajax="'select/payment-methods'"
                        :label="$LANG.Get('fields.defaultPaymentMethodId') + ':'"
                        :nullable="true"
                        class="half"></NevsSelect>
        </NevsCard>

        <NevsCard class="form">
            <h2>{{ $LANG.Get('labels.fiskalSettings') }}</h2>
            <NevsUpload v-model="client.fiskal_certificate" :label="$LANG.Get('fields.fiskalCertificate') + ':'"
                        class="half"></NevsUpload>
            <NevsTextField v-model="client.fiskal_password" :label="$LANG.Get('fields.fiskalPassword') + ':'"
                           class="half"></NevsTextField>
        </NevsCard>

        <NevsCard class="form">
            <h2>{{ $LANG.Get('labels.invoicesSettings') }}</h2>
            <NevsNumberField v-model="client.invoice_due_days" :decimal-places="0"
                             :label="$LANG.Get('fields.invoiceDueDays') + ':'"
                             class="half"></NevsNumberField>
            <NevsTextField v-model="client.invoice_place_of_issue"
                           :label="$LANG.Get('fields.invoicePlaceOfIssue') + ':'"
                           class="half"></NevsTextField>
            <NevsTextArea v-model="client.invoice_footer_template_hr"
                          :label="$LANG.Get('fields.invoiceFooterTemplateHr') + ':'"></NevsTextArea>
            <NevsTextArea v-model="client.invoice_footer_template_en"
                          :label="$LANG.Get('fields.invoiceFooterTemplateEn') + ':'"></NevsTextArea>
            <NevsSelect v-model="client.invoice_default_customer_id" :ajax="'select/customers'"
                        :label="$LANG.Get('fields.defaultCustomerId') + ':'" :nullable="true"></NevsSelect>
            <table class="sequences">
                <tr>
                    <th colspan="6">{{ $LANG.Get('labels.invoiceSequences') }}</th>
                </tr>
                <tr>
                    <th class="sequences-actions"></th>
                    <th>{{ $LANG.Get('fields.format') }}</th>
                    <th class="sequences-location-column">{{ $LANG.Get('fields.location') }}</th>
                    <th class="sequences-device-column">{{ $LANG.Get('fields.device') }}</th>
                    <th class="sequences-checkbox-column">{{ $LANG.Get('fields.fiskal') }}</th>
                    <th class="sequences-checkbox-column">{{ $LANG.Get('fields.defaultM') }}</th>
                </tr>
                <tr v-for="(sequence, key) in sequences.invoice" :key="key">
                    <td class="sequences-actions">
            <span v-if="!sequence.readonly" :title="$LANG.Get('tooltips.removeSequence')"
                  @click="removeSequence('invoice', key)"><i
                class="fa-solid fa-trash"></i></span>&nbsp;
                        <span v-if="sequence.id !== 0" :title="$LANG.Get('tooltips.setNextNumber')"
                              @click="setNext('invoice', key)"><i class="fa-solid fa-pen"></i></span>
                    </td>
                    <td>
                        <NevsTextField v-model="sequence.format" :readonly="sequence.readonly"></NevsTextField>
                    </td>
                    <td>
                        <NevsTextField v-model="sequence.location" :readonly="sequence.readonly"></NevsTextField>
                    </td>
                    <td>
                        <NevsTextField v-model="sequence.device" :readonly="sequence.readonly"></NevsTextField>
                    </td>
                    <td class="sequences-checkbox-column">
                        <NevsCheckbox v-model="sequence.fiskal" :readonly="sequence.readonly"></NevsCheckbox>
                    </td>
                    <td class="sequences-checkbox-column">
                        <NevsCheckbox v-model="sequence.default"></NevsCheckbox>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <NevsButton @click="addSequence('invoice')">{{ $LANG.Get('buttons.add') }}</NevsButton>
                    </td>
                </tr>
            </table>
            <div v-if="invoiceSequencesError!==''" class="sequences-disclaimer nevs-text-error">{{
                    invoiceSequencesError
                }}
            </div>
            <div class="sequences-disclaimer">{{ $LANG.Get('texts.sequencesDisclaimerInvoice') }}</div>
        </NevsCard>

        <NevsCard class="form">
            <h2>{{ $LANG.Get('labels.offersSettings') }}</h2>
            <NevsNumberField v-model="client.offer_valid_days" :decimal-places="0"
                             :label="$LANG.Get('fields.offerValidUntil') + ':'"
                             class="half"></NevsNumberField>
            <NevsTextArea v-model="client.offer_footer_template_hr"
                          :label="$LANG.Get('fields.offerFooterTemplateHr') + ':'"></NevsTextArea>
            <NevsTextArea v-model="client.offer_footer_template_en"
                          :label="$LANG.Get('fields.offerFooterTemplateEn') + ':'"></NevsTextArea>
            <table class="sequences">
                <tr>
                    <th colspan="6">{{ $LANG.Get('labels.offerSequences') }}</th>
                </tr>
                <tr>
                    <th class="sequences-actions"></th>
                    <th>{{ $LANG.Get('fields.format') }}</th>
                    <th class="sequences-location-column">{{ $LANG.Get('fields.location') }}</th>
                    <th class="sequences-device-column">{{ $LANG.Get('fields.device') }}</th>
                    <th class="sequences-checkbox-column">{{ $LANG.Get('fields.defaultM') }}</th>
                </tr>
                <tr v-for="(sequence, key) in sequences.offer" :key="key">
                    <td class="sequences-actions">
            <span v-if="!sequence.readonly" :title="$LANG.Get('tooltips.removeSequence')"
                  @click="removeSequence('offer', key)"><i
                class="fa-solid fa-trash"></i></span>&nbsp;
                        <span v-if="sequence.id !== 0" :title="$LANG.Get('tooltips.setNextNumber')"
                              @click="setNext('offer', key)"><i class="fa-solid fa-pen"></i></span>
                    </td>
                    <td>
                        <NevsTextField v-model="sequence.format" :readonly="sequence.readonly"></NevsTextField>
                    </td>
                    <td>
                        <NevsTextField v-model="sequence.location" :readonly="sequence.readonly"></NevsTextField>
                    </td>
                    <td>
                        <NevsTextField v-model="sequence.device" :readonly="sequence.readonly"></NevsTextField>
                    </td>
                    <td class="sequences-checkbox-column">
                        <NevsCheckbox v-model="sequence.default"></NevsCheckbox>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <NevsButton @click="addSequence('offer')">{{ $LANG.Get('buttons.add') }}</NevsButton>
                    </td>
                </tr>
            </table>
            <div v-if="offerSequencesError!==''" class="sequences-disclaimer nevs-text-error">{{
                    offerSequencesError
                }}
            </div>
            <div class="sequences-disclaimer">{{ $LANG.Get('texts.sequencesDisclaimerOffer') }}</div>
        </NevsCard>
        <NevsCard class="form">
            <h2>{{ $LANG.Get('labels.workOrderSettings') }}</h2>
            <NevsTextArea v-model="client.work_order_footer_template_hr"
                          :label="$LANG.Get('fields.workOrderFooterTemplateHr') + ':'"></NevsTextArea>
            <NevsTextArea v-model="client.work_order_footer_template_en"
                          :label="$LANG.Get('fields.workOrderFooterTemplateEn') + ':'"></NevsTextArea>
            <table class="sequences">
                <tr>
                    <th colspan="6">{{ $LANG.Get('labels.workOrderSequences') }}</th>
                </tr>
                <tr>
                    <th class="sequences-actions"></th>
                    <th>{{ $LANG.Get('fields.format') }}</th>
                    <th class="sequences-checkbox-column">{{ $LANG.Get('fields.defaultM') }}</th>
                </tr>
                <tr v-for="(sequence, key) in sequences.work_order" :key="key">
                    <td class="sequences-actions">
                        <span v-if="!sequence.readonly" :title="$LANG.Get('tooltips.removeSequence')" @click="removeSequence('work_order', key)"><i class="fa-solid fa-trash"></i></span>&nbsp;
                        <span v-if="sequence.id !== 0" :title="$LANG.Get('tooltips.setNextNumber')" @click="setNext('work_order', key)"><i class="fa-solid fa-pen"></i></span>
                    </td>
                    <td>
                        <NevsTextField v-model="sequence.format" :readonly="sequence.readonly"></NevsTextField>
                    </td>
                    <td class="sequences-checkbox-column">
                        <NevsCheckbox v-model="sequence.default"></NevsCheckbox>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <NevsButton @click="addSequence('work_order')">{{ $LANG.Get('buttons.add') }}</NevsButton>
                    </td>
                </tr>
            </table>
            <div class="sequences-disclaimer">{{ $LANG.Get('texts.sequencesDisclaimerWorkOrder') }}</div>
            <div v-if="workOrderSequencesError!==''" class="sequences-disclaimer nevs-text-error">{{
                    workOrderSequencesError
                }}
            </div>
        </NevsCard>
        <NevsCard class="templates" v-html="$LANG.Get('texts.invoiceOfferTemplateHelp')"></NevsCard>
    </div>
</template>

<script>
import Client from "@/models/Client";
import NevsButton from "@/components/nevs/NevsButton";
import NevsCard from "@/components/nevs/NevsCard";
import NevsNumberField from "@/components/nevs/NevsNumberField";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsTextArea from "@/components/nevs/NevsTextArea";
import NevsSelect from "@/components/nevs/NevsSelect";
import NevsCheckbox from "@/components/nevs/NevsCheckbox";
import NevsUpload from "@/components/nevs/NevsUpload";

export default {
    name: "FinancialSettings",
    components: {
        NevsUpload,
        NevsCheckbox,
        NevsSelect,
        NevsTextArea,
        NevsTextField,
        NevsNumberField,
        NevsCard,
        NevsButton
    },
    data() {
        return {
            loaded: 0,
            client: new Client(),
            clientValidation: new Client({}, true),
            sequences: {
                invoice: [],
                offer: [],
                work_order: []
            },
            sequenceTemplates: {
                invoice: {
                    id: 0,
                    format: '%slijed%-%prostor%-%uredaj%',
                    location: '01',
                    device: '01',
                    fiskal: false,
                    default: false
                },
                offer: {
                    id: 0,
                    format: '%slijed%-%prostor%-%uredaj%',
                    location: '01',
                    device: '01',
                    fiskal: false,
                    default: false
                },
                work_order: {
                    id: 0,
                    format: '%godina4%-%slijed%',
                    default: false
                }
            },
            invoiceSequencesError: '',
            offerSequencesError: '',
            workOrderSequencesError: '',
        }
    },
    methods: {
        setNext(type, key) {
            let vm = this;
            this.$API.APICall('get', type.split('_').join('-') + '-sequences/' + this.sequences[type][key].id, {}, (data, success) => {
                if (success) {
                    let message = vm.$LANG.Get('alerts.changeNumber');
                    message = message.replace('%next_number%', data.next);
                    vm.$LOCAL_BUS.TriggerEvent('popup', {
                        type: 'input', text: message, callback: (response) => {
                            if (response !== null) {
                                this.$API.APICall('put', type.split('_').join('-') + '-sequences/' + this.sequences[type][key].id, {next: response}, (data, success) => {
                                    if (!success) {
                                        vm.$LOCAL_BUS.TriggerEvent('popup', {
                                            text: vm.$LANG.Get('alerts.serverError'),
                                            type: 'alert'
                                        });
                                    }
                                });
                            }
                        }
                    });
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        },
        removeSequence(type, key) {
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {
                type: 'confirm', text: this.$LANG.Get('alerts.sequenceDeletionQuestion'), callback: (response) => {
                    if (response) {
                        vm.sequences[type].splice(key, 1);
                    }
                }
            });
        },
        addSequence(type) {
                    this.sequences[type].push(JSON.parse(JSON.stringify(this.sequenceTemplates[type])));
        },
        saveClick() {
            let valid = true;

            let invoiceSequenceDefaults = 0;
            let invoiceFiskalSequenceExists = false;
            for (let sequence of this.sequences.invoice) {
                if (sequence.default) {
                    invoiceSequenceDefaults++;
                }
                if (sequence.fiskal) {
                    invoiceFiskalSequenceExists = true;
                }
            }
            if (invoiceSequenceDefaults > 1) {
                valid = false;
                this.invoiceSequencesError = this.$LANG.Get('labels.tooManyDefaults');
                this.$LOCAL_BUS.TriggerEvent('popup', {
                    text: this.$LANG.Get('alerts.sequencesTooManyDefaults'),
                    type: 'alert'
                });
            } else if (invoiceFiskalSequenceExists && (this.client.fiskal_password === '' || this.client.fiskal_certificate.name === "")) {
                valid = false;
                this.$LOCAL_BUS.TriggerEvent('popup', {
                    text: this.$LANG.Get('alerts.sequencesFiskalFail'),
                    type: 'alert'
                })
            } else {
                this.invoiceSequencesError = '';
            }

            let offerSequenceDefaults = 0;
            for (let sequence of this.sequences.offer) {
                if (sequence.default) {
                    offerSequenceDefaults++;
                }
            }
            if (offerSequenceDefaults > 1) {
                valid = false;
                this.offerSequencesError = this.$LANG.Get('labels.tooManyDefaults');
                this.$LOCAL_BUS.TriggerEvent('popup', {
                    text: this.$LANG.Get('alerts.sequencesTooManyDefaults'),
                    type: 'alert'
                })
            } else {
                this.offerSequencesError = '';
            }

            if (valid) {
                let vm = this;
                this.$API.APICall('put', 'client', this.client, (data, success) => {
                    if (success) {
                        vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                        vm.$API.APICall('get', 'session', {}, (data, success) => {
                            if (success) {
                                vm.$store.commit('setUser', data.user);
                            }
                        }, false);
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                });
                this.$API.APICall('put', 'invoice-sequences', this.sequences.invoice, (data, success) => {
                    if (success) {
                        vm.sequences.invoice = data;
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                });
                this.$API.APICall('put', 'offer-sequences', this.sequences.offer, (data, success) => {
                    if (success) {
                        vm.sequences.offer = data;
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                });
                this.$API.APICall('put', 'work-order-sequences', this.sequences.work_order, (data, success) => {
                    if (success) {
                        vm.sequences.work_order = data;
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                });
            }
        }
    },
    mounted() {
        if (!this.$store.state.user.permissions.includes('ADMINISTRATOR')) {
            this.$LOCAL_BUS.TriggerEvent('popup', {text: this.$LANG.Get('alerts.unauthorized'), type: 'alert'});
            this.$router.push('/');
        }
        this.$store.commit('selectMenu', 'settings');
        this.$store.commit('selectSubMenu', 'financialSettings');
        this.$store.commit('setBreadcrumbs', [
            {
                label: this.$LANG.Get('modules.settings'),
                link: null
            },
            {
                label: this.$LANG.Get('modules.financialSettings'),
                link: null
            }
        ]);

        let vm = this;
        this.$API.APICall('get', 'client', {}, (data, success) => {
            if (success) {
                if (data.client !== null) {
                    vm.client.Fill(data.client);
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            } else {
                vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
            }
            vm.$nextTick(() => {
                vm.loaded++;
            });
        });
        this.$API.APICall('get', 'invoice-sequences', {}, (data, success) => {
            if (success) {
                vm.sequences.invoice = data;
            } else {
                vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
            }
            vm.$nextTick(() => {
                vm.loaded++;
            });
        });
        this.$API.APICall('get', 'offer-sequences', {}, (data, success) => {
            if (success) {
                vm.sequences.offer = data;
            } else {
                vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
            }
            vm.$nextTick(() => {
                vm.loaded++;
            });
        });
        this.$API.APICall('get', 'work-order-sequences', {}, (data, success) => {
            if (success) {
                vm.sequences.work_order = data;
            } else {
                vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
            }
            vm.$nextTick(() => {
                vm.loaded++;
            });
        });
    }
}
</script>

<style scoped>
.form {
    max-width: 700px;
    margin-bottom: 10px;
}

.half {
    width: 50% !important;
}

.templates {
    max-width: 600px;
    position: fixed;
    right: 10px;
    top: 108px;
}

.sequences {
    margin: 5px;
    width: calc(100% - 10px);
    border-collapse: collapse;
    box-sizing: border-box;
}

.sequences th {
    border: 1px solid #b2b2b2;
    padding: 10px;
}

.sequences td {
    border: 1px solid #b2b2b2;
    padding: 10px;
}

.sequences-location-column {
    width: 50px;
}

.sequences-device-column {
    width: 50px;
}

.sequences-actions {
    width: 40px;
    text-align: center;
    border-right: none !important;
}

.sequences-checkbox-column {
    width: 20px;
    text-align: center;
}

.sequences-actions span {
    cursor: pointer;
}

.sequences th:nth-child(2) {
    border-left: none !important;
}

.sequences tr:first-child th {
    border: none !important;
}

.sequences td:first-child {
    padding-right: 0;
}

.sequences td:nth-child(2) {
    border-left: none !important;
    padding-left: 0;
}

>>> .template-label {
    font-weight: bold;
}

.sequences-disclaimer {
    margin: 5px;
}

@media only screen and (max-width: 1580px) {
    .templates {
        max-width: 400px;
    }
}

@media only screen and (max-width: 1420px) {
    .templates {
        position: relative;
        max-width: 600px;
        top: 0;
        right: 0;
    }
}

@media only screen and (max-width: 600px) {
    .half {
        width: 100% !important;
    }
}

</style>