class Client {
    id = null;
    name = '';
    oib = '';
    iban = '';
    swift = '';
    address = '';
    city = '';
    postal_code = '';
    country = '';
    invoice_place_of_issue = '';
    invoice_email_subject_hr = '';
    invoice_email_template_hr = '';
    invoice_email_subject_en = '';
    invoice_email_template_en = '';
    invoice_footer_template_hr = '';
    invoice_footer_template_en = '';
    invoice_due_days = 0;
    offer_email_subject_hr = '';
    offer_email_template_hr = '';
    offer_email_subject_en = '';
    offer_email_template_en = '';
    offer_footer_template_hr = '';
    offer_footer_template_en = '';
    offer_valid_days = 0;
    tax_system = false;
    logo = {};
    reply_to = '';
    invoice_bcc = '';
    offer_bcc = '';
    fiskal = false;
    fiskal_certificate = {};
    fiskal_password = '';
    default_measurement_unit_id = 0;
    invoice_default_customer_id = 0;
    default_payment_method_id = 0;
    pdf_orientation = 'L';
    pdf_customer_offset_x = 0;
    pdf_customer_offset_y = 0;
    work_order_email_subject_hr = '';
    work_order_email_template_hr = '';
    work_order_email_subject_en = '';
    work_order_email_template_en = '';
    work_order_footer_template_hr = '';
    work_order_footer_template_en = '';


    constructor(data = {}, validation = false) {
        for (const [key, value] of Object.entries(data)) {
            this[key] = value;
        }

        if (validation) {
            for (const [key] of Object.entries(this)) {
                this[key] = '';
            }
        }
    }

    Fill(data) {
        for (const [key] of Object.entries(this)) {
            if (data[key] !== undefined) {
                this[key] = data[key];
            }
        }
    }
}

export default Client;
