<template>
    <div v-if="loaded" class="nevs-content">
        <div class="nevs-content-top-buttons">
            <NevsButton class="success" @click="saveClick()"><i class="fa-solid fa-floppy-disk"></i>
                {{ $LANG.Get('buttons.save') }}
            </NevsButton>&nbsp;
        </div>
        <NevsCard class="form">
            <h2>{{ $LANG.Get('labels.sendingSettings') }}</h2>
            <NevsTextField v-model="client.reply_to" :label="$LANG.Get('fields.replyTo') + ':'"></NevsTextField>
            <NevsTextField v-model="client.invoice_bcc" :label="$LANG.Get('fields.invoiceBCC') + ':'"></NevsTextField>
            <NevsTextField v-model="client.offer_bcc" :label="$LANG.Get('fields.offerBCC') + ':'"></NevsTextField>
        </NevsCard>
        <NevsCard class="form">
            <h2>{{ $LANG.Get('labels.invoicesAndOffersSettings') }}</h2>
            <NevsTextField v-model="client.invoice_email_subject_hr"
                           :label="$LANG.Get('fields.invoiceEmailSubjectHr') + ':'"></NevsTextField>
            <NevsTextArea v-model="client.invoice_email_template_hr"
                          :label="$LANG.Get('fields.invoiceEmailTemplateHr') + ':'"></NevsTextArea>
            <NevsTextField v-model="client.invoice_email_subject_en"
                           :label="$LANG.Get('fields.invoiceEmailSubjectEn') + ':'"></NevsTextField>
            <NevsTextArea v-model="client.invoice_email_template_en"
                          :label="$LANG.Get('fields.invoiceEmailTemplateEn') + ':'"></NevsTextArea>
            <NevsTextField v-model="client.offer_email_subject_hr"
                           :label="$LANG.Get('fields.offerEmailSubjectHr') + ':'"></NevsTextField>
            <NevsTextArea v-model="client.offer_email_template_hr"
                          :label="$LANG.Get('fields.offerEmailTemplateHr') + ':'"></NevsTextArea>
            <NevsTextField v-model="client.offer_email_subject_en"
                           :label="$LANG.Get('fields.offerEmailSubjectEn') + ':'"></NevsTextField>
            <NevsTextArea v-model="client.offer_email_template_en"
                          :label="$LANG.Get('fields.offerEmailTemplateEn') + ':'"></NevsTextArea>
            <NevsTextField v-model="client.work_order_email_subject_hr"
                           :label="$LANG.Get('fields.workOrderEmailSubjectHr') + ':'"></NevsTextField>
            <NevsTextArea v-model="client.work_order_email_template_hr"
                          :label="$LANG.Get('fields.workOrderEmailTemplateHr') + ':'"></NevsTextArea>
            <NevsTextField v-model="client.work_order_email_subject_en"
                           :label="$LANG.Get('fields.workOrderEmailSubjectEn') + ':'"></NevsTextField>
            <NevsTextArea v-model="client.work_order_email_template_en"
                          :label="$LANG.Get('fields.workOrderEmailTemplateEn') + ':'"></NevsTextArea>
        </NevsCard>
        <NevsCard class="templates" v-html="$LANG.Get('texts.invoiceOfferTemplateHelp')"></NevsCard>
    </div>
</template>

<script>
import Client from "@/models/Client";
import NevsButton from "@/components/nevs/NevsButton";
import NevsCard from "@/components/nevs/NevsCard";
import NevsTextArea from "@/components/nevs/NevsTextArea";
import NevsTextField from "@/components/nevs/NevsTextField";

export default {
    name: "GeneralData",
    components: {NevsTextField, NevsTextArea, NevsCard, NevsButton},
    data() {
        return {
            loaded: false,
            client: new Client(),
            clientValidation: new Client({}, true)
        }
    },
    methods: {
        saveClick() {
            let valid = true;

            if (valid) {
                let vm = this;
                this.$API.APICall('put', 'client', this.client, (data, success) => {
                    if (success) {
                        vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                        vm.$API.APICall('get', 'session', {}, (data, success) => {
                            if (success) {
                                vm.$store.commit('setUser', data.user);
                            }
                        }, false);
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                })
            }
        }
    },
    mounted() {
        if (!this.$store.state.user.permissions.includes('ADMINISTRATOR')) {
            this.$LOCAL_BUS.TriggerEvent('popup', {text: this.$LANG.Get('alerts.unauthorized'), type: 'alert'});
            this.$router.push('/');
        }
        this.$store.commit('selectMenu', 'settings');
        this.$store.commit('selectSubMenu', 'emailSettings');
        this.$store.commit('setBreadcrumbs', [
            {
                label: this.$LANG.Get('modules.settings'),
                link: null
            },
            {
                label: this.$LANG.Get('modules.emailSettings'),
                link: null
            }
        ]);

        let vm = this;
        this.$API.APICall('get', 'client', {}, (data, success) => {
            if (success) {
                if (data.client !== null) {
                    vm.client.Fill(data.client);
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            } else {
                vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
            }
            vm.$nextTick(() => {
                vm.loaded = true;
            });
        });
    }
}
</script>

<style scoped>
.form {
    max-width: 600px;
    margin-bottom: 10px;
}

.templates {
    max-width: 600px;
    position: fixed;
    right: 10px;
    top: 108px;
}

>>> .template-label {
    font-weight: bold;
}

@media only screen and (max-width: 1462px) {
    .templates {
        max-width: 400px;
    }
}

@media only screen and (max-width: 1316px) {
    .templates {
        position: relative;
        max-width: 600px;
        top: 0;
        right: 0;
    }
}

</style>