<template>
    <div class="nevs-content">
        <div class="nevs-content-top-buttons">
            <NevsButton class="primary" @click="addClick">
                <i class="fa-solid fa-plus"></i>
                {{ $LANG.Get('buttons.add') }}
            </NevsButton>
            <NevsButton class="primary" @click="filterClick">
                <i class="fa-solid fa-filter"></i>
                {{ $LANG.Get('buttons.filter') }}
            </NevsButton>
            <NevsButton class="primary" @click="excelClick">
                <i class="fa-solid fa-file-excel"></i>
                {{ $LANG.Get('buttons.excel') }}
            </NevsButton>
        </div>
        <NevsCard v-if="showFilters" class="filters">
            <NevsTextField v-model="tableData.filters.number" :label="$LANG.Get('fields.number')"></NevsTextField>
            <NevsSelect :nullable="true" v-model="tableData.filters.customer_id" :ajax="'select/customers'"
                        :label="$LANG.Get('fields.customer')"></NevsSelect>
            <NevsDateField v-model="tableData.filters.date_of_issue_from"
                           :label="$LANG.Get('fields.date_of_issue_from')"></NevsDateField>
            <NevsDateField v-model="tableData.filters.date_of_issue_to"
                           :label="$LANG.Get('fields.date_of_issue_to')"></NevsDateField>
            <NevsSelect :nullable="true" v-model="tableData.filters.work_order_status_id"
                        :ajax="'select/work-order-statuses'"
                        :label="$LANG.Get('fields.status')"></NevsSelect>
        </NevsCard>
        <NevsCard>
            <NevsTable
                :default-sort="tableData.defaultSort"
                :fields="tableData.fields"
                :height="tableData.height"
                :total-records="tableData.totalRecords"
                @reload="reloadTable">
                <tr v-for="(item, key) in tableData.records" :key="key">
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.number }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.date_of_issue_display }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.customer_display }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)"><span
                        :class="'status-pill-' + item.work_order_status_id">{{ item.status_display }}</span></td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.invoices_display }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.amount_display }}</td>
                    <td class="nevs-table-actions">
            <span v-show="item.deletable" :title="$LANG.Get('tooltips.delete')" class="nevs-table-button"
                  @click="deleteClick(item)"><i class="fa-solid fa-trash"></i></span>
                        <span :title="$LANG.Get('tooltips.createInvoice')" class="nevs-table-button"
                              @click="invoiceClick(item.id)"><i class="fa-solid fa-file-invoice-dollar"></i></span>
                        <span :title="$LANG.Get('tooltips.openInNewWindow')" class="nevs-table-button"
                              @click="openClick(item.id, true)"><i class="fa-solid fa-up-right-from-square"></i></span>
                        <span :title="$LANG.Get('tooltips.copy')" class="nevs-table-button"
                              @click="copyClick(item.id)"><i class="fa-solid fa-clone"></i></span>
                        <span :title="$LANG.Get('tooltips.email')" class="nevs-table-button"
                              @click="emailClick(item)"><i class="fa-solid fa-envelope"></i></span>
                        <span :title="$LANG.Get('tooltips.pdf')" class="nevs-table-button"
                              @click="pdfClick(item.pdf_link)"><i class="fa-solid fa-file-pdf"></i></span>
                    </td>
                </tr>
            </NevsTable>
        </NevsCard>
    </div>
</template>

<script>
import NevsCard from "@/components/nevs/NevsCard";
import NevsButton from "@/components/nevs/NevsButton";
import NevsTable from "@/components/nevs/NevsTable";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsDateField from "@/components/nevs/NevsDateField";
import NevsSelect from "@/components/nevs/NevsSelect";

export default {
    name: "ModuleWorkOrders",
    components: {NevsSelect, NevsDateField, NevsTextField, NevsTable, NevsButton, NevsCard},
    data() {
        return {
            tableData: {
                fields: [
                    {
                        name: 'number',
                        label: this.$LANG.Get('fields.number')
                    },
                    {
                        name: 'date_of_issue',
                        label: this.$LANG.Get('fields.dateOfIssue')
                    },
                    {
                        name: 'customer',
                        label: this.$LANG.Get('fields.customer'),
                        sortable: false
                    },
                    {
                        name: 'status',
                        label: this.$LANG.Get('fields.status'),
                        sortable: false
                    },
                    {
                        name: 'invoice',
                        label: this.$LANG.Get('labels.invoice'),
                        sortable: false
                    },
                    {
                        name: 'amount',
                        label: this.$LANG.Get('labels.amount')
                    },
                    {
                        name: 'actions',
                        width: '50px',
                        label: ''
                    }
                ],
                filters: {
                    number: '',
                    date_of_issue_from: null,
                    date_of_issue_to: null,
                    work_order_status_id: null,
                    customer_id: null,
                    item: ''
                },
                records: [],
                lastRequest: null,
                totalRecords: 0,
                defaultSort: {
                    field: 'number',
                    descending: true
                },
                filterTimer: null,
                height: '0px'
            },
            showFilters: false
        }
    },
    watch: {
        'tableData.filters': {
            handler() {
                if (this.filterTimer !== null) {
                    clearTimeout(this.filterTimer);
                }
                let vm = this;
                this.filterTimer = setTimeout(function () {
                    vm.reloadTable(vm.tableData.lastRequest);
                    vm.filterTimerOn = false;
                }, 500);
            },
            deep: true
        }
    },
    methods: {
        emailClick(workOrder) {
            let vm = this;
            this.$API.APICall('get', 'customers/' + workOrder.customer_id, {}, (data, success) => {
                if (success) {
                    let emails = data.customer.emails;
                    let initialValue = '';
                    for (let key in emails) {
                        if (initialValue !== '') {
                            initialValue += ' ; '
                        }
                        initialValue += emails[key].address;
                    }
                    vm.$LOCAL_BUS.TriggerEvent('popup', {
                        type: 'input',
                        text: vm.$LANG.Get('alerts.enterEmails'),
                        default: initialValue,
                        callback: (response) => {
                            if (response !== null) {
                                vm.$API.APICall('post', 'work-orders/email/' + workOrder.id, {destination: response}, (data, success) => {
                                    if (success) {
                                        vm.$LOCAL_BUS.TriggerEvent('popup', {
                                            text: vm.$LANG.Get('alerts.emailSent'),
                                            type: 'alert'
                                        });
                                        vm.$CROSS_TAB_BUS.TriggerEvent('reload-work-orders', {});
                                    }
                                });
                            }
                        }
                    });
                }
            });
        },
        pdfClick(link) {
            window.open(link);
        },
        deleteClick(workOrder) {
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {
                type: 'confirm', text: this.$LANG.Get('alerts.workOrderDeletionQuestion'), callback: (response) => {
                    if (response) {
                        vm.$API.APICall('delete', 'work-orders/' + workOrder.id, {}, (data, success) => {
                            if (success) {
                                vm.$CROSS_TAB_BUS.TriggerEvent('reload-work-orders', {});
                            } else {
                                vm.$LOCAL_BUS.TriggerEvent('popup', {
                                    text: vm.$LANG.Get('alerts.serverError'),
                                    type: 'alert'
                                });
                            }
                        });
                    }
                }
            });
        },
        filterClick() {
            this.showFilters = !this.showFilters;
            if (!this.showFilters) {
                this.tableData.filters = {
                    number: '',
                    date_of_issue_from: null,
                    date_of_issue_to: null,
                    work_order_status_id: null,
                    customer_id: null,
                    item: ''
                };
            }
        },
        excelClick() {
            let excelRequest = JSON.parse(JSON.stringify(this.tableData.lastRequest));
            excelRequest.rowsPerPage = 0;

            let vm = this;
            vm.$API.APICall('get', 'work-orders/excel', excelRequest, (data, success) => {
                if (success) {
                    window.open(data.link);
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        },
        copyClick(id) {
            this.$router.push('/work-orders/' + id + '/copy');
        },
        invoiceClick(id) {
            this.$router.push('/invoices/' + id + '/work-order');
        },
        openClick(id, newWindow = false) {
            if (newWindow) {
                let routeData = this.$router.resolve('/work-orders/' + id + '/edit');
                window.open(routeData.href);
            } else {
                this.$router.push('/work-orders/' + id + '/edit');
            }
        },
        addClick() {
            this.$router.push('/work-orders/0/add');
        },
        reloadTable(request) {
            let vm = this;
            request.filters = this.tableData.filters;
            this.tableData.lastRequest = JSON.parse(JSON.stringify(request));
            this.$API.APICall('get', 'work-orders', request, (data, success) => {
                if (success) {
                    vm.tableData.records = data.records;
                    vm.tableData.totalRecords = data.total_records;
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        },
        resolveWindowResize() {
            this.tableData.height = window.innerHeight - 205 + 'px';
        }
    },
    mounted() {
        this.$store.commit('selectMenu', 'workOrders');
        this.$store.commit('selectSubMenu', null);
        this.$store.commit('setBreadcrumbs', [
            {
                label: this.$LANG.Get('modules.workOrders'),
                link: null
            }
        ]);
        window.addEventListener('resize', this.resolveWindowResize);
        this.resolveWindowResize();
        let vm = this;
        this.$CROSS_TAB_BUS.ListenToEvent('reload-work-orders', () => {
            vm.reloadTable(vm.tableData.lastRequest);
        });
    }
}
</script>

<style scoped>
.filters {
    margin-bottom: 10px;
}

>>> .filters .nevs-field {
    width: 300px !important;
}

>>> .nevs-table-header td {
    padding-bottom: 10px !important;
}

.status-pill-1 {
    width: 100%;
    text-align: center;
    display: inline-block;
    background: #00b7eb;
    padding: 5px;
    border-radius: 5px;
}

.status-pill-2 {
    width: 100%;
    text-align: center;
    display: inline-block;
    background: #f2de0b;
    padding: 5px;
    border-radius: 5px;
}

.status-pill-3 {
    width: 100%;
    text-align: center;
    display: inline-block;
    background: #81c784;
    padding: 5px;
    border-radius: 5px;
}

.status-pill-4 {
    width: 100%;
    text-align: center;
    display: inline-block;
    background: #81c784;
    padding: 5px;
    border-radius: 5px;
}

.status-pill-5 {
    width: 100%;
    text-align: center;
    display: inline-block;
    background: #ff3939;
    padding: 5px;
    border-radius: 5px;
}

</style>