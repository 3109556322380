<template>
  <div class="nevs-content">
    <div v-if="productPopup.show" class="nevs-popup-container">
      <div class="nevs-popup">
        <div class="nevs-popup-text">
          <NevsTextField v-model="productPopup.data.name" :error="productPopup.validation.name"
                         :label="'*' + $LANG.Get('fields.nameHr') + ':'"></NevsTextField>
          <NevsTextField v-model="productPopup.data.name_en" :label="$LANG.Get('fields.nameEn') + ':'"></NevsTextField>
          <NevsSelect v-model="productPopup.data.measurement_unit_id" :ajax="'select/measurement-units'"
                      :label="$LANG.Get('fields.measurementUnit')"></NevsSelect>
          <NevsNumberField v-model="productPopup.data.price" :label="'*' + $LANG.Get('fields.priceWithoutTax').split('%currency%').join($HELPERS.GetDefaultCurrency()) + ':'"
                           class="product-popup-half"></NevsNumberField>
          <NevsNumberField v-show="$store.state.user.client.tax_system" v-model="productPopup.data.tax"
                           :label="'*' + $LANG.Get('fields.tax') + ':'"
                           class="product-popup-half"></NevsNumberField>
        </div>
        <div class="nevs-popup-buttons">
          <NevsButton class="error" @click="productPopup.cancel()">{{ $LANG.Get('buttons.cancel') }}</NevsButton>
          <NevsButton class="success" @click="productPopup.save()">{{ $LANG.Get('buttons.ok') }}</NevsButton>
        </div>
      </div>
    </div>
    <NevsCard v-if="groupsReady" class="form">
      <h2>{{ $LANG.Get('labels.productGroups') }}</h2>
      <div v-for="(group, key) in groupsData.records" :key="key" class="group">
        <span :title="$LANG.Get('tooltips.deleteProductGroup')" class="group-button" @click="deleteGroup(group)"><i
            class="fa-solid fa-trash"></i></span>
        <span :title="$LANG.Get('tooltips.editProductGroup')" class="group-button" @click="editGroup(group)"><i
            class="fa-solid fa-pen"></i></span>
        <span :title="$LANG.Get('tooltips.showProducts')" class="group-button" @click="loadProducts(group)"><i
            class="fa-solid fa-cubes"></i></span>
        <span>{{
            group.name
          }}</span>
      </div>
      <NevsButton class="primary" @click="addGroup">{{ $LANG.Get('buttons.add') }}</NevsButton>
    </NevsCard>
    <NevsCard v-if="currentGroup !== null && productsReady" class="form">
      <h2>{{ currentGroup.name }}</h2>
      <div v-for="(product, key) in productsData.records" :key="key" class="product">
        <span :title="$LANG.Get('tooltips.deleteProduct')" class="product-button"
              @click="deleteProduct(product)"><i class="fa-solid fa-trash"></i></span>
        <span :title="$LANG.Get('tooltips.editProduct')" class="product-button" @click="editProduct(product)"><i
            class="fa-solid fa-pen"></i></span>
        <span class="product-name">{{ product.name }} - {{ product.price_display }}</span>
      </div>
      <NevsButton class="primary" @click="addProduct">{{ $LANG.Get('buttons.add') }}</NevsButton>
    </NevsCard>
  </div>
</template>

<script>

import NevsCard from "@/components/nevs/NevsCard";
import NevsButton from "@/components/nevs/NevsButton";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsNumberField from "@/components/nevs/NevsNumberField";
import NevsSelect from "@/components/nevs/NevsSelect";

export default {
  name: "ModuleProducts",
  components: {NevsSelect, NevsNumberField, NevsTextField, NevsButton, NevsCard},
  data() {
    return {
      groupsReady: false,
      productsReady: false,
      currentGroup: null,
      groupsData: {
        filters: {
          name: ''
        },
        records: [],
        filterTimer: null
      },
      productsData: {
        filters: {
          name: '',
          product_group_id: null
        },
        records: [],
        filterTimer: null
      },
      productPopup: {
        show: false,
        data: {
          id: 0,
          name: '',
          name_en: '',
          measurement_unit_id: (this.$store.state.user.client.default_measurement_unit_id !== null) ? this.$store.state.user.client.default_measurement_unit_id : 1,
          price: 0,
          tax: this.$store.state.settings.DEFAULT_TAX
        },
        validation: {
          name: ''
        },
        reset: () => {
          this.productPopup.data = {
            id: 0,
            name: '',
            name_en: '',
            measurement_unit_id: (this.$store.state.user.client.default_measurement_unit_id !== null) ? this.$store.state.user.client.default_measurement_unit_id : 1,
            product_group_id: 0,
            price: 0,
            tax: this.$store.state.settings.DEFAULT_TAX
          };
          this.productPopup.validation = {
            name: ''
          }
        },
        cancel: () => {
          this.productPopup.show = false;
        },
        save: () => {
          let success = true;
          if (this.productPopup.data.name === '') {
            success = false;
            this.productPopup.validation.name = this.$LANG.Get('labels.mandatoryField');
          } else {
            this.productPopup.validation.name = '';
          }
          if (success) {
            this.productPopup.data.product_group_id = this.currentGroup.id;
            let method = (this.productPopup.data.id === 0) ? 'post' : 'put';
            let action = (this.productPopup.data.id === 0) ? 'products' : 'products/' + this.productPopup.data.id;
            let vm = this;
            this.$API.APICall(method, action, this.productPopup.data, (data, success) => {
              vm.productPopup.show = false;
              vm.productPopup.reset();
              if (success) {
                vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                vm.loadProducts(vm.currentGroup);
              } else {
                vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
              }
            });
          }
        }
      }
    }
  },
  watch: {
    'groupsData.filters': {
      handler() {
        if (this.filterTimer !== null) {
          clearTimeout(this.filterTimer);
        }
        let vm = this;
        this.filterTimer = setTimeout(function () {
          vm.reloadGroups();
          vm.filterTimerOn = false;
        }, 500);
      },
      deep: true
    },
    'productsData.filters.name': {
      handler() {
        if (this.filterTimer !== null) {
          clearTimeout(this.filterTimer);
        }
        let vm = this;
        this.filterTimer = setTimeout(function () {
          vm.reloadProducts();
          vm.filterTimerOn = false;
        }, 500);
      },
      deep: true
    }
  },
  methods: {
    editProduct(product) {
      let vm = this;
      this.$API.APICall('get', 'products/' + product.id, {}, (data, success) => {
        if (success && data.product !== null) {
          vm.productPopup.reset();
          vm.productPopup.data = JSON.parse(JSON.stringify(data.product));
          vm.productPopup.show = true;
        } else {
          vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
        }
      });
    },
    addProduct() {
      this.productPopup.reset();
      this.productPopup.show = true;
    },
    deleteProduct(product) {
      let vm = this;
      this.$LOCAL_BUS.TriggerEvent('popup', {
        type: 'confirm', text: this.$LANG.Get('alerts.generalRemovalQuestion'), callback: (response) => {
          if (response) {
            this.$API.APICall('delete', 'products/' + product.id, {}, (data, success) => {
              if (success) {
                vm.reloadProducts();
              } else {
                vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
              }
            });
          }
        }
      });
    },
    loadProducts(group) {
      this.currentGroup = group;
      this.productsData.filters.product_group_id = group.id;
      this.reloadProducts();
    },
    deleteGroup(group) {
      let vm = this;
      this.$LOCAL_BUS.TriggerEvent('popup', {
        type: 'confirm', text: this.$LANG.Get('alerts.generalRemovalQuestion'), callback: (response) => {
          if (response) {
            this.$API.APICall('delete', 'product-groups/' + group.id, {}, (data, success) => {
              if (success) {
                vm.reloadGroups();
              } else {
                vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
              }
            });
          }
        }
      });
    },
    addGroup() {
      let vm = this;
      this.$LOCAL_BUS.TriggerEvent('popup', {
        type: 'input', text: vm.$LANG.Get('alerts.addGroup'), callback: (response) => {
          if (response !== null && response !== '') {
            this.$API.APICall('post', 'product-groups', {name: response}, (data, success) => {
              if (success) {
                vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                vm.reloadGroups();
              } else {
                vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
              }
            });
          }
        }
      });
    },
    editGroup(group) {
      let vm = this;
      this.$LOCAL_BUS.TriggerEvent('popup', {
        type: 'input', text: vm.$LANG.Get('alerts.addGroup'), default: group.name, callback: (response) => {
          if (response !== null && response !== '') {
            this.$API.APICall('put', 'product-groups/' + group.id, {name: response}, (data, success) => {
              if (success) {
                vm.reloadGroups();
              } else {
                vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
              }
            });
          }
        }
      });
    },
    reloadGroups() {
      let vm = this;
      let request = {
        sort: {
          field: 'name',
          descending: false
        },
        filters: this.groupsData.filters,
        currentPage: 1,
        rowsPerPage: 0
      };
      this.$API.APICall('get', 'product-groups', request, (data, success) => {
        if (success) {
          vm.groupsReady = true;
          vm.groupsData.records = data.records;
        } else {
          vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
        }
      });
    },
    reloadProducts() {
      let vm = this;
      let request = {
        sort: {
          field: 'name',
          descending: false
        },
        filters: this.productsData.filters,
        currentPage: 1,
        rowsPerPage: 0
      };
      this.$API.APICall('get', 'products', request, (data, success) => {
        if (success) {
          vm.productsReady = true;
          vm.productsData.records = data.records;
        } else {
          vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
        }
      });
    }
  },
  mounted() {
    this.$store.commit('selectMenu', 'products');
    this.$store.commit('selectSubMenu', null);
    this.$store.commit('setBreadcrumbs', [
      {
        label: this.$LANG.Get('modules.products'),
        link: null
      }
    ]);
    this.reloadGroups();
  }
}
</script>

<style scoped>
.form {
  min-width: 400px;
  margin-bottom: 10px;
  margin-right: 10px;
  float: left;
}

.form .nevs-button {
  margin-left: 5px;
}

.group {
  margin: 20px;
  font-size: 18px;
}

.group-button {
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}

.product {
  margin: 20px;
  font-size: 18px;
}

.product-button {
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}

.product-popup-half {
  width: 50% !important;
}

@media only screen and (max-width: 1400px) {
  .product-popup-half {
    width: 100% !important;
  }
}

@media only screen and (max-width: 439px) {
  .form {
    min-width: 0;
    width: calc(100% - 20px);
  }

}

</style>